.navbar-toggler {
    width: 20px;
    height: 20px;
    position: relative;
    transition: .5s ease-in-out;
}

.navbar-toggler,
.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
    outline: none;
    box-shadow: none;
    border: 0;
}

.navbar-toggler span {
    margin: 0;
    padding: 0;
}

.toggler-icon {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    background: $second;
    border-radius: 1px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: .25s ease-in-out;
}

.middle-bar {
    margin-top: 0;
}

.navbar-toggler .top-bar {
    margin-top: 0;
    transform: rotate(135deg);
}

.navbar-toggler .middle-bar {
    opacity: 0;
    filter: alpha(opacity=0);
}

.navbar-toggler .bottom-bar {
    margin-top: 0;
    transform: rotate(-135deg);
}

.navbar-toggler.collapsed .top-bar {
    margin-top: -20px;
    transform: rotate(0deg);
}

.navbar-toggler.collapsed .middle-bar {
    opacity: 1;
    filter: alpha(opacity=100);
}

.navbar-toggler.collapsed .bottom-bar {
    margin-top: 20px;
    transform: rotate(0deg);
}

.navbar-toggler.collapsed .toggler-icon {
    background: $first;
}
.navbar {
    transition: .25s ease-in-out;
}
.logo {
    width: 10rem;
}

.navbar-collapse {
    transition: .25s ease-in-out;
}

.navbar-collapse.collapse.show {
    padding: 50px 10px;
}

.show {
    background: #fff !important;
    transition: .25s ease-in-out;
    border-radius: 5px;
}

@media (min-width: 992px) {
    .bg-none {
        margin-top: 50px;
    }
    .logo {
        width: 15rem;
    }
    .show {
        background: none !important;
    }
}

.logo-small {
    width: 5rem;
}

.bg-none, {
    background-color:rgba(0, 0, 0, 0) !important;

    a {
        color: #000 !important;
    }
}

.dropdown-menu {
    .nav-link {
        padding-left: 20px;
    }
}

.bg-light {
    transition: .25s ease-in-out;
}

.navbar-nav .dropdown-menu {
    background: #fff !important;
}
