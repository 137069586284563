a:hover {
    color: $first-hover !important;
    opacity: .7;
}

.active {
    color: $first !important;
}

.text-first{
    color: $first !important;
}

.text-second {
    color: $second !important;
}

.text-third {
    color: $third !important;
}

.bg-first,
.btn-first {
    background: $first;
    color: #fff;
}

.btn-outline-first {
    border: 1px solid $first;
    color: #fff;
    transition: .25s ease-in-out;
}
.btn-outline-first:hover {
    border: 1px solid $first;
    color: #fff !important;
    opacity: .7 !important;
}

.bg-second,
.btn-second {
    background: $second;
    color: #fff;
}
.btn-second,
.btn-outline-second {
    border-style: solid !important;
    border-width: 2px !important;
    border-image: linear-gradient(135deg, rgb(123, 0, 252), rgb(244, 9, 153)) 1 !important;
}

.bg-third {
    background: $third;
    color: #fff;
}

.bg-blue {
    background: #0B66FF !important;
}

.bg-pink {
    background: #C1A397 !important;
}

.bg-slate {
    background: $slate;
    color: #fff;
}

.bg-glass {
    background: rgb(255 209 252 / 17%);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
}

.fa-google {
    color: #DB4437 !important;
}
