// Body
$first-hover: rgba(244, 9, 153, 0.45);
$first: #F40999;
$second: #7A01FD;
$third: #552989;
$slate: #0f041e;
$glass: rgb(255 255 255 / 16%);

// Typography
$font-family-sans-serif: "Catamaran", sans-serif;
$font-size-base: 1.2rem;
$line-height-base: 1.6;

// Carousel
$carousel-control-prev-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$first}'><path d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/></svg>");
$carousel-control-next-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$first}'><path d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/></svg>");
$carousel-control-opacity: .3;
$carousel-indicator-active-bg: $second;
$carousel-indicator-opacity: .3;
