// Fonts
@import url('https://fonts.googleapis.com/css2?family=Catamaran:wght@100;500;700;900&family=Inter:wght@100;400;700;900&display=swap');
// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

//Global
@import 'global';
@import 'colors';
@import 'nav';
@import "animation";

//Pages
@import 'home';
