* {
    //color: #000;
}

body {
    overflow-x: hidden;
}

h1,
.h1 {
    //font-size: 5.8vw;
    font-size: 4rem;
}

.h3 {
    font-size: 2em !important;
}

.text-sm {
    font-size: 12px;
}

a{
    text-decoration: none;
}

.strike-through {
    text-decoration: line-through;
}

section {
    padding-top: 125px;
    padding-bottom: 125px;
}

.btn {
    border: none;
    border-radius: 0;
}

.btn-primary {
    color: #fff;
}

.btn-lg {
    //border: none;
    border-radius: 0;
    padding: 2% 10%;
}

.btn-outline:hover {
    box-shadow: 1px -2px 21px 9px rgb(244 9 153 / 21%) !important;
}

#hero {
    padding-top: 290px;
    padding-bottom: 125px;
}

.page-hero {
    padding-top: 125px;
    padding-bottom: 125px;
}

//#sub-header {
//    margin-top: -150px;
//}

a .card {
    transition: 0.3s;
}

a .card:hover {
    margin-top: -10px;
    box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
}

.card-img-top {
    height: 350px;
    object-fit: cover;
    object-position: center top;
    width: 100%;
}
.img-container {
    overflow: hidden;
    height: 200px; /* Or whatever height you prefer */
}
.scroll-on-hover {
    transition: transform 4s ease; /* Adjust time as needed */
    width: 100%;
    height: auto;
    transform: translateY(0);
}
.img-container:hover .scroll-on-hover {
    transform: translateY(-85%);
}
svg {
    margin-top: -2px !important;
    margin-bottom: -2px !important;
}

.rocket {
    margin: -20% 0 -7% 0;
}

.list-group-item-light {
    background-color: #f7f7f7 !important;
}

footer {
    background: linear-gradient(90deg, rgba(244,9,98,0.60) 0%, rgba(83,1,253,1) 100%);
    color: #fff !important;
    a {
        color: #fff !important;
    }
    a:hover {
        opacity: .7;
    }
}

#home-links {
    background-image: url("/img/southgates-bg-black.jpg");
    background-position: center right;
    background-repeat: no-repeat;
    background-size: cover;
}

#features {
    background: rgba(244,9,153,0.604079131652661);
}

#services,
#prices {
    background: #55127c;
}

.full-width {
    width: 100%;
}
.hero-rocket {
    width: 25%;
}

.content-left {
    padding:0 0 0 10%;
}
.content-right {
    padding:0 10% 0 0;
}

.active {
    color: $primary !important;
}

.bg-light {
    background: #fff !important;
}

@media (max-width: 991px) {
    #hero {
        padding-top: 125px;
    }
    .content-right {
        padding:0 10% 0 10%;
    }
}

.fab:before {
    font-size: 72px;
    background: linear-gradient(135deg, rgb(123, 0, 252), rgb(244, 9, 153));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: initial;
}

.fade-up{
    position: relative;
    transform: translateY(150px);
    opacity: 0;
    transition: 1s all ease;
}
.fade-left{
    position: relative;
    transform: translateX(-150px);
    opacity: 0;
    transition: 1s all ease;
}
.fade-right{
    position: relative;
    transform: translateX(150px);
    opacity: 0;
    transition: 1s all ease;
}
.reveal.animated,
.onload.animated {
    transform: translateX(0);
    opacity: 1;
}

.gradient-bg {
    background: linear-gradient(90deg, rgba(83, 1, 253, 100) 0%, #F4096299 100%);
}

.image-border {
    border-radius: 50px;
}

.rounded-25 {
    border-radius: 25px;
}

#testimonials .card {
    min-height: 750px;
}

@media (min-width: 768px) {
    .left-overlap-top {
        margin-top: -150px;
    }
    #testimonials .card {
        min-height: 450px;
    }
}

@media (min-width: 992px) {
    .left-overlap-top-further {
        margin-top: -500px;
    }
    .left-overlap-top {
        margin-top: -250px;
    }
}

@media (min-width: 1200px) {
    .right-overlap-top {
        margin-top: -250px;
    }
}
